<script setup lang="ts">
    const props = defineProps({
  title: null
});

    const displayed = ref(false);
    onMounted(async () => {
        await wait(300);
        displayed.value = true;
    });
</script>

<template>
    <div class="h-[200px]">
        <transition
            enter-active-class="duration-500 ease-out"
            enter-from-class="translate-y-[25px] blur opacity-0"
            enter-to-class="translate-y-0 opacity-100 blur-none"
        >
            <div
                v-if="displayed"
                class="py-6"
            >
                <div class="flex justify-center">
                    <div class="max-w-[100px] h-[100px]">
                        <Lottie json="https://shared-assets.trustup.io/lottie/empty.json" />
                    </div>
                </div>
                <p class="text-sm text-center text-gray-600">{{ title ?? $t('page.empty_content.title') }}</p>
            </div>
        </transition>
    </div>
</template>
